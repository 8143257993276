import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { TemplateProject } from '../../templates';
import { ProjectImage, ProjectImageDouble } from '../../components';
export const meta = {
  title: 'Logflare',
  hint: 'Logflare SaaS event logger',
  order: 64,
  type: 'project',
  published: true,
  seoTitle: 'Logflare',
  seoDescription: 'Logflare - UX and graphic design for a SaaS event log management platform.'
};
export const content = {
  images: {
    hero: {
      src: '/images/logflare/logflare-intro.jpg',
      alt: 'UX Design - Log Management Platform | Logflare',
      density: [1, 2, 4],
      breakpoint: ['s', 'm', 'l']
    },
    bottom: {
      src: '/images/logflare/logflare-end.jpg',
      alt: 'Mobile Design - UX Design | Logflare',
      density: [1, 2, 4],
      breakpoint: ['s', 'm', 'l']
    },
    thumbnail: {
      src: '/images/logflare/logflare-thumb.jpg',
      alt: 'Logflare',
      density: [1, 2, 4]
    }
  },
  tags: ['SaaS', 'responsive', 'UI', 'branding', 'logo', 'tech'],
  excerpt: 'Logflare is a log management platform for the next generation of developers. It allows users to store and analyze large numbers of event logs without breaking the bank.',
  description: 'Logflare is a log management platform for the next generation of developers. It allows users to store and analyze large numbers of event logs without breaking the bank. We created a brand style guide including a new logo, a project website layout and a logger application template.',
  problem: 'Since the platform originally launched in early 2019 the number of active users has skyrocketed. Even after more than 100 billion logs collected, the Logflare project still had not developed brand guidelines. The site had other problems too. The site explained service concepts well but lacked the marketing content needed to drive paid subscription sign-ups. The system didn’t promote credibility well enough for many to trust storing data on Logflare servers.',
  solution: 'First, we researched the target audience and competitors to address some user concerns around data privacy and pricing transparency. Next, we created a mood board, new logo and a collection of patterns — all in a style guide inspired by hacker subculture. Finally, we designed a new site with more persuasive content with a dedicated dark theme.',
  figures: [{
    value: '123B',
    label: 'Events logged to date in the system'
  }, {
    value: '226',
    label: 'Project GitHub stars'
  }, {
    value: '7,500',
    label: 'Average number of events collected each second'
  }]
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  content,
  _frontmatter
};
const MDXLayout = TemplateProject;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABxbBAf//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEAAQUCX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABkQAQACAwAAAAAAAAAAAAAAAAEAESBBcf/aAAgBAQABPyGLeg5j/9oADAMBAAIAAwAAABADz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABoQAAIDAQEAAAAAAAAAAAAAAAERACExEEH/2gAIAQEAAT8QGgp37kSQ5jpQFGGzP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/12982aa375cf05df18298c9d77291d54/d2432/logflare-target.webp 314w", "/static/12982aa375cf05df18298c9d77291d54/41786/logflare-target.webp 628w", "/static/12982aa375cf05df18298c9d77291d54/d4599/logflare-target.webp 1256w", "/static/12982aa375cf05df18298c9d77291d54/72bc3/logflare-target.webp 1884w", "/static/12982aa375cf05df18298c9d77291d54/51747/logflare-target.webp 2512w", "/static/12982aa375cf05df18298c9d77291d54/4e71a/logflare-target.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/12982aa375cf05df18298c9d77291d54/7a743/logflare-target.jpg 314w", "/static/12982aa375cf05df18298c9d77291d54/6f105/logflare-target.jpg 628w", "/static/12982aa375cf05df18298c9d77291d54/10d53/logflare-target.jpg 1256w", "/static/12982aa375cf05df18298c9d77291d54/b2e4b/logflare-target.jpg 1884w", "/static/12982aa375cf05df18298c9d77291d54/fdcb3/logflare-target.jpg 2512w", "/static/12982aa375cf05df18298c9d77291d54/78bd4/logflare-target.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/12982aa375cf05df18298c9d77291d54/10d53/logflare-target.jpg",
            "alt": "Target Audience - UI Design | Logflare",
            "title": "Target Audience - UI Design | Logflare",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwABBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeK4KDbH/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAECEBESE//aAAgBAQABBQI1ThXR4P/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAMAAwAAAAAAAAAAAAAAAAEQEQAxUf/aAAgBAQAGPwLLo8jlK//EABsQAAMAAgMAAAAAAAAAAAAAAAABERAhMUFh/9oACAEBAAE/IZqll3DAvTshXhj2z//aAAwDAQACAAMAAAAQs8//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxAn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAQADAQEAAAAAAAAAAAAAAREAIUEQYf/aAAgBAQABPxAaOFmCsjdFPlV74jRUuweYxRl10nLiowLwz//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/9c5a809737e413413ab47408105de367/d2432/logflare-mood.webp 314w", "/static/9c5a809737e413413ab47408105de367/41786/logflare-mood.webp 628w", "/static/9c5a809737e413413ab47408105de367/d4599/logflare-mood.webp 1256w", "/static/9c5a809737e413413ab47408105de367/72bc3/logflare-mood.webp 1884w", "/static/9c5a809737e413413ab47408105de367/51747/logflare-mood.webp 2512w", "/static/9c5a809737e413413ab47408105de367/4e71a/logflare-mood.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/9c5a809737e413413ab47408105de367/7a743/logflare-mood.jpg 314w", "/static/9c5a809737e413413ab47408105de367/6f105/logflare-mood.jpg 628w", "/static/9c5a809737e413413ab47408105de367/10d53/logflare-mood.jpg 1256w", "/static/9c5a809737e413413ab47408105de367/b2e4b/logflare-mood.jpg 1884w", "/static/9c5a809737e413413ab47408105de367/fdcb3/logflare-mood.jpg 2512w", "/static/9c5a809737e413413ab47408105de367/78bd4/logflare-mood.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/9c5a809737e413413ab47408105de367/10d53/logflare-mood.jpg",
            "alt": "Moodboard - UI Design | Logflare",
            "title": "Moodboard - UI Design | Logflare",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQBA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGF0yogf//EABgQAQEBAQEAAAAAAAAAAAAAAAIDBAAQ/9oACAEBAAEFAjCiNYuXg0oC2hWPf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAQACAgMAAAAAAAAAAAAAAAEAEBEhAiIx/9oACAEBAAY/Ash7O4FHHBqAhqv/xAAZEAEAAwEBAAAAAAAAAAAAAAABABAhETH/2gAIAQEAAT8hFE542ECI5jQQcENPF3K//9oADAMBAAIAAwAAABAP7//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAQEBAQADAQAAAAAAAAAAAAERACExQWHB/9oACAEBAAE/EGPStEpluJHbsX8dR8aI4wtrlhJLfWJ7fuCb/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/33c5f33b279c8c8748cf6b26d703ef4a/d2432/logflare-logo-process.webp 314w", "/static/33c5f33b279c8c8748cf6b26d703ef4a/41786/logflare-logo-process.webp 628w", "/static/33c5f33b279c8c8748cf6b26d703ef4a/d4599/logflare-logo-process.webp 1256w", "/static/33c5f33b279c8c8748cf6b26d703ef4a/72bc3/logflare-logo-process.webp 1884w", "/static/33c5f33b279c8c8748cf6b26d703ef4a/51747/logflare-logo-process.webp 2512w", "/static/33c5f33b279c8c8748cf6b26d703ef4a/4e71a/logflare-logo-process.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/33c5f33b279c8c8748cf6b26d703ef4a/7a743/logflare-logo-process.jpg 314w", "/static/33c5f33b279c8c8748cf6b26d703ef4a/6f105/logflare-logo-process.jpg 628w", "/static/33c5f33b279c8c8748cf6b26d703ef4a/10d53/logflare-logo-process.jpg 1256w", "/static/33c5f33b279c8c8748cf6b26d703ef4a/b2e4b/logflare-logo-process.jpg 1884w", "/static/33c5f33b279c8c8748cf6b26d703ef4a/fdcb3/logflare-logo-process.jpg 2512w", "/static/33c5f33b279c8c8748cf6b26d703ef4a/78bd4/logflare-logo-process.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/33c5f33b279c8c8748cf6b26d703ef4a/10d53/logflare-logo-process.jpg",
            "alt": "Logo Design Process - UI Design | Logflare",
            "title": "Logo Design Process - UI Design | Logflare",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAcWpBDD/xAAWEAADAAAAAAAAAAAAAAAAAAAAASD/2gAIAQEAAQUCHP8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhAAAwAAAAAAAAAAAAAAAAAAACAx/9oACAEBAAY/AiL/AP/EABgQAQEAAwAAAAAAAAAAAAAAAAEQABFh/9oACAEBAAE/IQwPF2z/2gAMAwEAAgADAAAAEIMP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAQEBAAMAAAAAAAAAAAAAAREAMRBBUf/aAAgBAQABPxBFgV+aGpJ7DTVOOU6vj//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/107825536901dd48f55be8f3f5b4d696/d2432/logflare-logo-final.webp 314w", "/static/107825536901dd48f55be8f3f5b4d696/41786/logflare-logo-final.webp 628w", "/static/107825536901dd48f55be8f3f5b4d696/d4599/logflare-logo-final.webp 1256w", "/static/107825536901dd48f55be8f3f5b4d696/72bc3/logflare-logo-final.webp 1884w", "/static/107825536901dd48f55be8f3f5b4d696/51747/logflare-logo-final.webp 2512w", "/static/107825536901dd48f55be8f3f5b4d696/4e71a/logflare-logo-final.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/107825536901dd48f55be8f3f5b4d696/7a743/logflare-logo-final.jpg 314w", "/static/107825536901dd48f55be8f3f5b4d696/6f105/logflare-logo-final.jpg 628w", "/static/107825536901dd48f55be8f3f5b4d696/10d53/logflare-logo-final.jpg 1256w", "/static/107825536901dd48f55be8f3f5b4d696/b2e4b/logflare-logo-final.jpg 1884w", "/static/107825536901dd48f55be8f3f5b4d696/fdcb3/logflare-logo-final.jpg 2512w", "/static/107825536901dd48f55be8f3f5b4d696/78bd4/logflare-logo-final.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/107825536901dd48f55be8f3f5b4d696/10d53/logflare-logo-final.jpg",
            "alt": "Logflare Logo - UI Design | Logflare",
            "title": "Logflare Logo - UI Design | Logflare",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHFdHWskD//xAAYEAADAQEAAAAAAAAAAAAAAAAAAREQIf/aAAgBAQABBQIl1Ph//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8BJ//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABoQAQACAwEAAAAAAAAAAAAAAAEAERAhQVH/2gAIAQEAAT8hJQcK8MDTBEb3j//aAAwDAQACAAMAAAAQdw//xAAWEQEBAQAAAAAAAAAAAAAAAAARECH/2gAIAQMBAT8QLs//xAAXEQADAQAAAAAAAAAAAAAAAAABEBFB/9oACAECAQE/EBNX/8QAHBAAAgICAwAAAAAAAAAAAAAAAAERIRBRMUGB/9oACAEBAAE/EEl6JF9jkvEQ78GCto6IP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b5d30c9e788f2ced1712f845a1e901c1/d2432/logflare-logo-variants.webp 314w", "/static/b5d30c9e788f2ced1712f845a1e901c1/41786/logflare-logo-variants.webp 628w", "/static/b5d30c9e788f2ced1712f845a1e901c1/d4599/logflare-logo-variants.webp 1256w", "/static/b5d30c9e788f2ced1712f845a1e901c1/72bc3/logflare-logo-variants.webp 1884w", "/static/b5d30c9e788f2ced1712f845a1e901c1/51747/logflare-logo-variants.webp 2512w", "/static/b5d30c9e788f2ced1712f845a1e901c1/4e71a/logflare-logo-variants.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b5d30c9e788f2ced1712f845a1e901c1/7a743/logflare-logo-variants.jpg 314w", "/static/b5d30c9e788f2ced1712f845a1e901c1/6f105/logflare-logo-variants.jpg 628w", "/static/b5d30c9e788f2ced1712f845a1e901c1/10d53/logflare-logo-variants.jpg 1256w", "/static/b5d30c9e788f2ced1712f845a1e901c1/b2e4b/logflare-logo-variants.jpg 1884w", "/static/b5d30c9e788f2ced1712f845a1e901c1/fdcb3/logflare-logo-variants.jpg 2512w", "/static/b5d30c9e788f2ced1712f845a1e901c1/78bd4/logflare-logo-variants.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/b5d30c9e788f2ced1712f845a1e901c1/10d53/logflare-logo-variants.jpg",
            "alt": "Logflare Logo Variants - UI Design | Logflare",
            "title": "Logflare Logo Variants - UI Design | Logflare",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeJeySD/xAAYEAADAQEAAAAAAAAAAAAAAAAAAREQQf/aAAgBAQABBQI4VRvP/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8BJ//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABUQAQEAAAAAAAAAAAAAAAAAABAy/9oACAEBAAY/AmX/xAAaEAACAwEBAAAAAAAAAAAAAAABEQAQMSGh/9oACAEBAAE/IYQC/BWZWk37AHAq/9oADAMBAAIAAwAAABD/AM//xAAXEQEAAwAAAAAAAAAAAAAAAAABEBEh/9oACAEDAQE/EEbcf//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPxARf//EAB0QAQACAgIDAAAAAAAAAAAAAAEAETFRECFBYaH/2gAIAQEAAT8QMwgRNBwVfYVm7+Oog0i1ofGoz//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/baea921dd918ef969933d9a30ada8754/d2432/logflare-styles.webp 314w", "/static/baea921dd918ef969933d9a30ada8754/41786/logflare-styles.webp 628w", "/static/baea921dd918ef969933d9a30ada8754/d4599/logflare-styles.webp 1256w", "/static/baea921dd918ef969933d9a30ada8754/72bc3/logflare-styles.webp 1884w", "/static/baea921dd918ef969933d9a30ada8754/51747/logflare-styles.webp 2512w", "/static/baea921dd918ef969933d9a30ada8754/4e71a/logflare-styles.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/baea921dd918ef969933d9a30ada8754/7a743/logflare-styles.jpg 314w", "/static/baea921dd918ef969933d9a30ada8754/6f105/logflare-styles.jpg 628w", "/static/baea921dd918ef969933d9a30ada8754/10d53/logflare-styles.jpg 1256w", "/static/baea921dd918ef969933d9a30ada8754/b2e4b/logflare-styles.jpg 1884w", "/static/baea921dd918ef969933d9a30ada8754/fdcb3/logflare-styles.jpg 2512w", "/static/baea921dd918ef969933d9a30ada8754/78bd4/logflare-styles.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/baea921dd918ef969933d9a30ada8754/10d53/logflare-styles.jpg",
            "alt": "Style Guide - UI Design | Logflare",
            "title": "Style Guide - UI Design | Logflare",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHkWygA/8QAGBAAAgMAAAAAAAAAAAAAAAAAAQIAEiD/2gAIAQEAAQUCRC8qc//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAEFAAAAAAAAAAAAAAAAACEAAREgMf/aAAgBAQAGPwIQnwV//8QAGRABAQADAQAAAAAAAAAAAAAAAREAICFB/9oACAEBAAE/IUEXBesxbxuMdf/aAAwDAQACAAMAAAAQk8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAEAAgMBAQAAAAAAAAAAAAABETEQIdEAYf/aAAgBAQABPxABRqUtxB956FAiBZFt7O4GNmf/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/aa48bf214c52f485e560720b01c46838/d2432/logflare-typo.webp 314w", "/static/aa48bf214c52f485e560720b01c46838/41786/logflare-typo.webp 628w", "/static/aa48bf214c52f485e560720b01c46838/d4599/logflare-typo.webp 1256w", "/static/aa48bf214c52f485e560720b01c46838/72bc3/logflare-typo.webp 1884w", "/static/aa48bf214c52f485e560720b01c46838/51747/logflare-typo.webp 2512w", "/static/aa48bf214c52f485e560720b01c46838/4e71a/logflare-typo.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/aa48bf214c52f485e560720b01c46838/7a743/logflare-typo.jpg 314w", "/static/aa48bf214c52f485e560720b01c46838/6f105/logflare-typo.jpg 628w", "/static/aa48bf214c52f485e560720b01c46838/10d53/logflare-typo.jpg 1256w", "/static/aa48bf214c52f485e560720b01c46838/b2e4b/logflare-typo.jpg 1884w", "/static/aa48bf214c52f485e560720b01c46838/fdcb3/logflare-typo.jpg 2512w", "/static/aa48bf214c52f485e560720b01c46838/78bd4/logflare-typo.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/aa48bf214c52f485e560720b01c46838/10d53/logflare-typo.jpg",
            "alt": "Typography - UI Design | Logflare",
            "title": "Typography - UI Design | Logflare",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAYKrPB//xAAaEAEAAgMBAAAAAAAAAAAAAAADAgQAARMS/9oACAEBAAEFAip9DSp5xz4odrUDS3qePPqn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHBAAAQQDAQAAAAAAAAAAAAAAAgABERIhIjFh/9oACAEBAAY/AmO/fELWbZ44qzKEYLCGBfV5Vl//xAAaEAEBAQEBAQEAAAAAAAAAAAABEQAhMUFR/9oACAEBAAE/IWhCLMrtfH41DyA3PWwlHca/1GqETgd3/9oADAMBAAIAAwAAABBgL//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAECAQE/EKf/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMWHB/9oACAEBAAE/EBUHZbWp2/ImJCymhb75EVtwFdL5G47YYOr9l1VFql0J9gXg0GLh5P/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/f3c77ce569acc68b4627307542c5a4ac/d2432/logflare-wireframe.webp 314w", "/static/f3c77ce569acc68b4627307542c5a4ac/41786/logflare-wireframe.webp 628w", "/static/f3c77ce569acc68b4627307542c5a4ac/d4599/logflare-wireframe.webp 1256w", "/static/f3c77ce569acc68b4627307542c5a4ac/72bc3/logflare-wireframe.webp 1884w", "/static/f3c77ce569acc68b4627307542c5a4ac/51747/logflare-wireframe.webp 2512w", "/static/f3c77ce569acc68b4627307542c5a4ac/4e71a/logflare-wireframe.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/f3c77ce569acc68b4627307542c5a4ac/7a743/logflare-wireframe.jpg 314w", "/static/f3c77ce569acc68b4627307542c5a4ac/6f105/logflare-wireframe.jpg 628w", "/static/f3c77ce569acc68b4627307542c5a4ac/10d53/logflare-wireframe.jpg 1256w", "/static/f3c77ce569acc68b4627307542c5a4ac/b2e4b/logflare-wireframe.jpg 1884w", "/static/f3c77ce569acc68b4627307542c5a4ac/fdcb3/logflare-wireframe.jpg 2512w", "/static/f3c77ce569acc68b4627307542c5a4ac/78bd4/logflare-wireframe.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/f3c77ce569acc68b4627307542c5a4ac/10d53/logflare-wireframe.jpg",
            "alt": "Wireframes and Low Fidelity Prototype - UI Design | Logflare",
            "title": "Wireframes and Low Fidelity Prototype - UI Design | Logflare",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeLcUA//xAAXEAADAQAAAAAAAAAAAAAAAAAAASEg/9oACAEBAAEFAoOZ/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGhAAAgIDAAAAAAAAAAAAAAAAAREAIDFBwf/aAAgBAQABPyHAIl7gMuV//9oADAMBAAIAAwAAABAjD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAQABBQEAAAAAAAAAAAAAAAERABAhMYFB/9oACAEBAAE/EEyQtEwUaiewsUsvvbf/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/c5777be8552af329bfc15d121c5a362d/d2432/logflare-hifi-1.webp 314w", "/static/c5777be8552af329bfc15d121c5a362d/41786/logflare-hifi-1.webp 628w", "/static/c5777be8552af329bfc15d121c5a362d/d4599/logflare-hifi-1.webp 1256w", "/static/c5777be8552af329bfc15d121c5a362d/72bc3/logflare-hifi-1.webp 1884w", "/static/c5777be8552af329bfc15d121c5a362d/51747/logflare-hifi-1.webp 2512w", "/static/c5777be8552af329bfc15d121c5a362d/4e71a/logflare-hifi-1.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/c5777be8552af329bfc15d121c5a362d/7a743/logflare-hifi-1.jpg 314w", "/static/c5777be8552af329bfc15d121c5a362d/6f105/logflare-hifi-1.jpg 628w", "/static/c5777be8552af329bfc15d121c5a362d/10d53/logflare-hifi-1.jpg 1256w", "/static/c5777be8552af329bfc15d121c5a362d/b2e4b/logflare-hifi-1.jpg 1884w", "/static/c5777be8552af329bfc15d121c5a362d/fdcb3/logflare-hifi-1.jpg 2512w", "/static/c5777be8552af329bfc15d121c5a362d/78bd4/logflare-hifi-1.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/c5777be8552af329bfc15d121c5a362d/10d53/logflare-hifi-1.jpg",
            "alt": "High Fidelity Prototype - UI Design | Logflare",
            "title": "High Fidelity Prototype - UI Design | Logflare",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAcVlECD/xAAXEAADAQAAAAAAAAAAAAAAAAAAARAR/9oACAEBAAEFAh3VP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABwQAAICAgMAAAAAAAAAAAAAAAABESEQcTFBYf/aAAgBAQABPyGOzRLQ+SaXhIrrH//aAAwDAQACAAMAAAAQz8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPxAn/8QAGxABAAIDAQEAAAAAAAAAAAAAAQAxESFBEFH/2gAIAQEAAT8QqKmbY4IYpAD4VNw5DZUAGC/P/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/0eec6b57e25666d7e058b861e03fae9f/d2432/logflare-hifi-2.webp 314w", "/static/0eec6b57e25666d7e058b861e03fae9f/41786/logflare-hifi-2.webp 628w", "/static/0eec6b57e25666d7e058b861e03fae9f/d4599/logflare-hifi-2.webp 1256w", "/static/0eec6b57e25666d7e058b861e03fae9f/72bc3/logflare-hifi-2.webp 1884w", "/static/0eec6b57e25666d7e058b861e03fae9f/51747/logflare-hifi-2.webp 2512w", "/static/0eec6b57e25666d7e058b861e03fae9f/4e71a/logflare-hifi-2.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/0eec6b57e25666d7e058b861e03fae9f/7a743/logflare-hifi-2.jpg 314w", "/static/0eec6b57e25666d7e058b861e03fae9f/6f105/logflare-hifi-2.jpg 628w", "/static/0eec6b57e25666d7e058b861e03fae9f/10d53/logflare-hifi-2.jpg 1256w", "/static/0eec6b57e25666d7e058b861e03fae9f/b2e4b/logflare-hifi-2.jpg 1884w", "/static/0eec6b57e25666d7e058b861e03fae9f/fdcb3/logflare-hifi-2.jpg 2512w", "/static/0eec6b57e25666d7e058b861e03fae9f/78bd4/logflare-hifi-2.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/0eec6b57e25666d7e058b861e03fae9f/10d53/logflare-hifi-2.jpg",
            "alt": "User Interface Design - Logflare",
            "title": "User Interface Design - Logflare",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHh3zkA/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABBQJf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGhAAAgIDAAAAAAAAAAAAAAAAARAAETFBgf/aAAgBAQABPyEZhojXHa//2gAMAwEAAgADAAAAEDAP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGRABAQADAQAAAAAAAAAAAAAAAREAEGEh/9oACAEBAAE/EPQKHXBQTOtGVJr/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/6f5cfd52bf3b736b72a313d1a51dde4b/d2432/logflare-hifi-3.webp 314w", "/static/6f5cfd52bf3b736b72a313d1a51dde4b/41786/logflare-hifi-3.webp 628w", "/static/6f5cfd52bf3b736b72a313d1a51dde4b/d4599/logflare-hifi-3.webp 1256w", "/static/6f5cfd52bf3b736b72a313d1a51dde4b/72bc3/logflare-hifi-3.webp 1884w", "/static/6f5cfd52bf3b736b72a313d1a51dde4b/51747/logflare-hifi-3.webp 2512w", "/static/6f5cfd52bf3b736b72a313d1a51dde4b/4e71a/logflare-hifi-3.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/6f5cfd52bf3b736b72a313d1a51dde4b/7a743/logflare-hifi-3.jpg 314w", "/static/6f5cfd52bf3b736b72a313d1a51dde4b/6f105/logflare-hifi-3.jpg 628w", "/static/6f5cfd52bf3b736b72a313d1a51dde4b/10d53/logflare-hifi-3.jpg 1256w", "/static/6f5cfd52bf3b736b72a313d1a51dde4b/b2e4b/logflare-hifi-3.jpg 1884w", "/static/6f5cfd52bf3b736b72a313d1a51dde4b/fdcb3/logflare-hifi-3.jpg 2512w", "/static/6f5cfd52bf3b736b72a313d1a51dde4b/78bd4/logflare-hifi-3.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/6f5cfd52bf3b736b72a313d1a51dde4b/10d53/logflare-hifi-3.jpg",
            "alt": "Responsive Design - UI Design | Logflare",
            "title": "Responsive Design - UI Design | Logflare",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAcRqiAD/xAAXEAADAQAAAAAAAAAAAAAAAAAAARAR/9oACAEBAAEFAqjVP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABoQAAIDAQEAAAAAAAAAAAAAAAExABARIXH/2gAIAQEAAT8hD7GEB5RZBgjX/9oADAMBAAIAAwAAABBsD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABoQAQEAAwEBAAAAAAAAAAAAAAERABBRIWH/2gAIAQEAAT8QIigPuIWJONAmlzwG3R1//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/4c4b94d6423344eca9d3142df6d0a14b/d2432/logflare-hifi-4.webp 314w", "/static/4c4b94d6423344eca9d3142df6d0a14b/41786/logflare-hifi-4.webp 628w", "/static/4c4b94d6423344eca9d3142df6d0a14b/d4599/logflare-hifi-4.webp 1256w", "/static/4c4b94d6423344eca9d3142df6d0a14b/72bc3/logflare-hifi-4.webp 1884w", "/static/4c4b94d6423344eca9d3142df6d0a14b/51747/logflare-hifi-4.webp 2512w", "/static/4c4b94d6423344eca9d3142df6d0a14b/4e71a/logflare-hifi-4.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/4c4b94d6423344eca9d3142df6d0a14b/7a743/logflare-hifi-4.jpg 314w", "/static/4c4b94d6423344eca9d3142df6d0a14b/6f105/logflare-hifi-4.jpg 628w", "/static/4c4b94d6423344eca9d3142df6d0a14b/10d53/logflare-hifi-4.jpg 1256w", "/static/4c4b94d6423344eca9d3142df6d0a14b/b2e4b/logflare-hifi-4.jpg 1884w", "/static/4c4b94d6423344eca9d3142df6d0a14b/fdcb3/logflare-hifi-4.jpg 2512w", "/static/4c4b94d6423344eca9d3142df6d0a14b/78bd4/logflare-hifi-4.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/4c4b94d6423344eca9d3142df6d0a14b/10d53/logflare-hifi-4.jpg",
            "alt": "Prototyping - UI Design | Logflare",
            "title": "Prototyping - UI Design | Logflare",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      